<!--
<template>
<div>
  <CCard>
    <CCardBody class="pt-0">
      <div id="toolbar" class="pt-2">
        <strong class="pl-1">{{title}}({{siteTable.data.length}})</strong>
      </div>
      <v-table 
        id="siteTable" 
        data-toolbar="#toolbar" 
        :columns="siteTable.columns" 
        :data="siteTable.data" 
        :options="siteTable.options" 
        @on-check="onCheckSite" 
        @on-check-all="onCheckSiteAll" 
        @on-uncheck="onUncheckSite"
        @on-uncheck-all="onUncheckSiteAll" 
      ></v-table>
    </CCardBody>
    <CCardFooter>
      <CRow class="text-center">
        <CCol col="12" xl="12">
          <CButton color="warning" class="float-right" @click="openDeleteDialog('site')" v-show="isAdmin" :disabled="permission.delete == false || selected_site == null">
            삭제
          </CButton>
          <CButton color="info" class="float-right mr-1" @click="openDialog('site', 'update')" :disabled="permission.update == false || selected_site == null" >
            수정
          </CButton>
          <CButton color="success" class="float-right mr-1" @click="openDialog('site', 'create')" v-show="isAdmin" :disabled="permission.create == false">
            생성
          </CButton>
        </CCol>
      </CRow>
    </CCardFooter>
  </CCard>

  <CCard>
    <CCardBody class="pt-0">
      <div id="toolbar2" class="pt-2">
        <strong class="pl-1">{{device_name_title}}({{deviceTable.data.length}}) - 디바이스 목록</strong>
      </div>
      <v-table id="deviceTable" data-toolbar="#toolbar2" :columns="deviceTable.columns" :data="deviceTable.data" :options="deviceTable.options" @on-check="onCheckDevice" @on-uncheck="onUncheckDevice"></v-table>
    </CCardBody>
    <CCardFooter>
      <CRow class="text-center">
        <CCol col="12" xl="12">
          <CButton color="warning" class="float-right" @click="openSaveExcel()">엑셀저장</CButton>
          <CButton color="warning" class="float-right mr-1" @click="openRegisterDialog()" v-show="isAdmin" :disabled="permission.create == false || selected_device == null">등록</CButton>
          <CButton color="warning" class="float-right mr-1" @click="openDeleteDialog('device')" v-show="isAdmin" :disabled="permission.delete == false || selected_device == null">삭제</CButton>
          <CButton color="info" class="float-right mr-1" @click="openDialog('device', 'update')" v-show="isAdmin" :disabled="selected_device == null" >수정</CButton>
          <CButton color="success" class="float-right mr-1" @click="openDialog('device', 'create')" v-show="isAdmin" :disabled="permission.create == false">생성</CButton>
          <CButton color="danger" class="float-right mr-1" @click="openUploadDialog()" v-show="isAdmin" :disabled="permission.create == false">업로드</CButton>
        </CCol>
      </CRow>
    </CCardFooter>
  </CCard>

  <Confirm
      ref="confirmDialog"
      title="확인"
      which="delete"
      @hide="hideModal"
      color="warning"
  />
  <Progress
      ref="progressDialog"
      title="확인"
  />
  <CreateSite
      ref="siteDialog"
      :isAdmin.sync= "isAdmin"
      @hide="hideModal"
  />
  <CreateDevice
      ref="deviceDialog"
      :isAdmin.sync= "isAdmin"
      @hide="hideModal"
  />
  <RegistDevice
      ref="registDialog"
      @hide="hideModal"
  />
  <UploadDevice
      ref="uploadDialog"
      @hide="hideModal"
  />

</div>
</template>
-->

<script>
import SiteManage from '@/views/site/SiteManage';
import loopback from '@/services/loopback';
import moment from 'moment';
import utils from '@/services/utils';
import { SITE_TABLE_OPTIOINS, SITE_TABLE_COLUMS, DEVICE_TABLE_OPTIOINS, SRDS_DEVICE_TABLE_COLUMS } from "@/constants/siteSettings";

const PERMITTED = 'user';

export default {
  name: 'SRDSSiteManage',
  extends: SiteManage, 
  mounted() {
    this.deviceTable.columns = SRDS_DEVICE_TABLE_COLUMS;

    _.find(this.deviceTable.columns, { field: 'name' }).formatter = this.nameFormatter;
    _.find(this.deviceTable.columns, { field: 'prev_time' }).formatter = this.dateFormatter2;

    _.find(this.deviceTable.columns, { field: 'pre_sensor' }).formatter = this.pre_angleStatus;
    _.find(this.deviceTable.columns, { field: 'sensor' }).formatter = this.angleStatus;
    _.find(this.deviceTable.columns, { field: 'battery' }).formatter = this.batteryStatus;
    _.find(this.deviceTable.columns, { field: 'prev_time' }).formatter = this.dateFormatter2;
    _.find(this.deviceTable.columns, { field: 'sensor_time' }).formatter = this.dateFormatter;
    _.find(this.deviceTable.columns, { field: 'install_date' }).formatter = this.dateFormatter;

  },
  methods: {
    dateFormatter(value, row, index) {
      if (!_.get(row, "empty", false)) {
        // return moment(value).format('YYYY-MM-DD HH:mm:ss')
        return moment(value).format('\'YY-MM-DD HH:mm')
      } else {
        return "-"
      }
    },
    batteryStatus(value, row, index){
      var thresholds = utils.getThresholds();
      try {
        var level0= _.find(thresholds, {sensor_type : 1, level : 0 }).min;
        var level1= _.find(thresholds, {sensor_type : 1, level : 1 }).min;
        var level2= _.find(thresholds, {sensor_type : 1, level : 2 }).min;
        var level3= _.find(thresholds, {sensor_type : 1, level : 3 }).min;
      } catch(err) {
        return;
      }
      var result = "";
      //console.log(row.sensor);
      if (_.isUndefined(row.sensor))
        return "측정값 없음"
      row.sensor.forEach(function(e){
          //console.log(e);
          if (_.isUndefined(e.value))
            return
          if(e.type === 1){
              if(e.value >= level0) result = "<span style = 'color: green' > 좋음(" + e.value +"v)</span>";
              else if(e.value >= level0) result = "<span> 보통 </span>";
              else if(e.value >= level1) result = "<span style = 'color: orange' > 주의(" + e.value +"v)</span>";
              else if(e.value >= level2) result = "<span style = 'color: red' > 경고(" + e.value +"v)</span>";                            
              else result = "<span style = 'color: red; font-weight: bold'> 교체필요(" + e.value +"v)</span>";
          }
      });
      return result;
    },    
    angleStatus(value, row, index){
      //console.log(value);
      if (_.isUndefined(value))
        return
      var self = this
      var result = "";
      value.forEach(function(v) {
        if(v.type === 8 && (v.id >= 1 && v.id <=3)){
          if (!_.get(row, "empty", false)) {
            if (v.id === 0) v.id = 1;
            var description = _.find(self.sensor_type, {
              code: v.type,
              id: v.id
            });
            if (!description) return
            var desc_value;
            if(v.id === 1) desc_value='x';
            else if(v.id === 2) desc_value='y';
            else if(v.id === 3) desc_value='z';                        
            var desc = desc_value + ': ' + v.value + description.unit + ' ';
            if (v.type === 10) {
              if (self.isAdmin)
                result += desc
            } else {
              result += desc
            }
          } else {
            result = "측정값 없음"
          }
        }
      })
      return "<a href='" + this.getDeviceUrl(row) + "'>" + result + "</a>";
    },
    pre_angleStatus(value, row, index){
      if (_.isUndefined(value))
        return
      var self = this
      var result = "";
      
      if(_.isUndefined(value.x) || _.isUndefined(value.y) || _.isUndefined(value.z))
        return "측정값 없음";

      result += 'x: ' + value.x + '(º) ';
      result += 'y: ' + value.y + '(º) ';
      result += 'z: ' + value.z + '(º)';

      return result;
    },    
  }
}
</script>